import styled from "@emotion/styled";
import { Role } from "@hshrimal/salesleg";
import { FC } from "react";
import { FilterUrlParams } from "../../../../constants/filters";
import { useNavigateWithTeam } from "../../../../hooks/useNavigationWithTeam";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../../../hooks/useUrlQueryParam";

const RootContainer = styled.div<{ disableDrillDown: boolean | undefined }>(
  ({ disableDrillDown, theme }) => ({
    ...theme.salesleg.typography.paragraph.sm.medium,
    color: theme.salesleg.palette.neutral[700],
    ...((disableDrillDown === undefined || !disableDrillDown) && {
      textDecorationLine: "underline",
      textDecorationStyle: "dashed",
      cursor: "pointer",
    }),
  })
);

interface AssignedCellProps {
  name: string;
  role?: Role | undefined;
  id: string;
  disableDrillDown?: boolean | undefined;
}

const AssignedCell: FC<AssignedCellProps> = ({
  name,
  id,
  disableDrillDown,
}) => {
  const navigate = useNavigateWithTeam();
  const [createdDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [activeTab] = useUrlQueryParam("tab");
  const [leadSource] = useMultiSelectUrlQueryParam(FilterUrlParams.LEAD_SOURCE);
  const [group_by] = useUrlQueryParam(FilterUrlParams.GROUP_BY);

  const params = new URLSearchParams();
  if (createdDate) {
    params.append(FilterUrlParams.CREATION_DATE, createdDate);
  }

  if (leadSource.length > 0) {
    leadSource.forEach((source) => {
      params.append(FilterUrlParams.LEAD_SOURCE, source);
    });
  }

  if (activeTab) {
    params.append("tab", activeTab);
  }

  if (group_by) {
    params.append("group_by", group_by);
  }

  if (disableDrillDown) {
    return <>{name}</>;
  }

  return (
    <RootContainer
      disableDrillDown={disableDrillDown}
      onClick={
        !disableDrillDown
          ? () => {
              navigate(`/allLeads/${id}?${params.toString()}`);
            }
          : undefined
      }
    >
      {name}
    </RootContainer>
  );
};

export default AssignedCell;
