import { FC } from "react";
import styled from "styled-components";
import { Employee } from "../..";

interface BookingsCellProps {
  count: number;
  employee?: Employee | undefined;
  isRoot?: boolean | undefined;
}

const RootContainer = styled.div(() => ({}));
const BookingsCell: FC<BookingsCellProps> = ({ count, employee, isRoot }) => {
  return <RootContainer>{count}</RootContainer>;
};

export default BookingsCell;
