import { FC } from "react";
import styled from "styled-components";
import { Employee } from "../..";

interface VisitsDoneCellProps {
  count: number;
  employee?: Employee | undefined;
  isRoot?: boolean | undefined;
}

const RootContainer = styled.div(() => ({}));
const VisitsDoneCell: FC<VisitsDoneCellProps> = ({ count, employee, isRoot }) => {
  return <RootContainer>{count}</RootContainer>;
};

export default VisitsDoneCell;
