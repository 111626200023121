import styled from "@emotion/styled";
import { FC } from "react";
import { Employee } from "../..";
import { FilterUrlParams } from "../../../../constants/filters";
import { useNavigateWithTeam } from "../../../../hooks/useNavigationWithTeam";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../../../hooks/useUrlQueryParam";
import { getLeadsFilterUrl } from "../../../../utils/urlHelper";

interface TomorrowCellProps {
  tomorrow: number;
  employee?: Employee | undefined;
  projectId?: string | undefined;
  isRoot?: boolean | undefined;
}
const RootContainer = styled.div(() => ({
  cursor: "pointer",
}));

const TomorrowCell: FC<TomorrowCellProps> = ({
  tomorrow,
  employee,
  projectId,
  isRoot,
}) => {
  const navigate = useNavigateWithTeam();
  const [creationDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [leadSource] = useMultiSelectUrlQueryParam(FilterUrlParams.LEAD_SOURCE);

  const handleClick = () => {
    const queryString = getLeadsFilterUrl({
        creationDate,
        leadSource,
        projectId,
        followUp: "tomorrow",
        employee,
        isRoot,
      }
    );
    navigate(`/clients?${queryString}`);
  };
  return <RootContainer onClick={handleClick}>{tomorrow}</RootContainer>;
};

export default TomorrowCell;
