import { FC } from "react";
import styled from "styled-components";
import { Employee } from "../..";
import { FilterUrlParams } from "../../../../constants/filters";
import { useNavigateWithTeam } from "../../../../hooks/useNavigationWithTeam";
import { useUrlQueryParam } from "../../../../hooks/useUrlQueryParam";
import { getLeadsFilterUrl } from "../../../../utils/urlHelper";

interface ProspectsCellProps {
  count: number;
  employee?: Employee | undefined;
  isRoot?: boolean | undefined;
}

const RootContainer = styled.div(() => ({
  cursor: "pointer",
}));
const ProspectsCell : FC<ProspectsCellProps> = ({ count, employee, isRoot }) => {
  const navigate = useNavigateWithTeam();
  const [prospectDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const handleClick = () => {
    const queryString = getLeadsFilterUrl({
      employee,
      isRoot,
      prospectDate}
    );
    navigate(`/clients?${queryString}`);
  };return <RootContainer onClick={handleClick}>{count}</RootContainer>;
};

export default ProspectsCell;
