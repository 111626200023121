import styled from "@emotion/styled";
import { Role, SummaryV2 } from "@hshrimal/salesleg";
import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Table from "../../components/Table";
import { useGetSummaryQuery } from "../../redux/features/api/summary";
import theme from "../../theme/light/theme";
import AssignedCell from "./cells/common/AssignedCell";
import ContactedCell from "./cells/leadsReport/ContactedCell";
import InterestedCell from "./cells/leadsReport/InterestedCell";
import NegotiationCell from "./cells/leadsReport/NegotiationCell";
import NewCell from "./cells/leadsReport/NewCell";
import VisitDoneCell from "./cells/leadsReport/VisitDoneCell";
import VisitPlanedCell from "./cells/leadsReport/VisitPlanedCell";
import ActiveLeadTotalCell from "./cells/leadsReport/activeLeadsReport/ActiveLeadTotalCell";
import { DownloadContext } from "./context/DownloadContext";
import { useLeadsQualityReportRequest } from "./hooks/useLeadsQualityReportRequest";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));
const TableContainer = styled.div(() => ({
  paddingLeft: 32,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  backgroundColor: theme.palette.neutral[25],
  overflowY: "auto",
  flexGrow: 1,
  flexBasis: 0,
}));

const ActiveLeadsContent = () => {
  const { employeeId } = useParams();

  const columns = [
    { name: "Manager", width: 170 },
    { name: "Total", width: 170 },
    { name: "New", width: 170 },
    { name: "Contacted", width: 170 },
    { name: "Interested", width: 170 },
    { name: "Visit Planned", width: 170 },
    { name: "Visit Done", width: 170 },
    { name: "Negotiation", width: 170 },
  ];

  const leadsQualityReportRequest = useLeadsQualityReportRequest(
    "manager",
    employeeId
  );
  const { data: activeLeads, isLoading } = useGetSummaryQuery(
    leadsQualityReportRequest
  );
  const totalRowByActiveLeads: SummaryV2 & { id: string } = useMemo(() => {
    const total: SummaryV2 & { id: string } = {
      id: "total",
      employee: {
        id: "",
        name: "",
        role: "sales_lead" as Role,
        level: 0,
        status: "active",
        isRoot: false,
      },
      total_leads: 0,
      new_leads: 0,
      contacted_leads: 0,
      interested_leads: 0,
      visit_planned_leads: 0,
      visit_done_leads: 0,
      negotiation_leads: 0,
    };

    if (activeLeads) {
      total.employee.id = activeLeads[0].employee.id;
      activeLeads.forEach((row) => {
        total.total_leads += row.total_leads;
        total.new_leads += row.new_leads;
        total.contacted_leads += row.contacted_leads;
        total.interested_leads += row.interested_leads;
        total.visit_planned_leads += row.visit_planned_leads;
        total.visit_done_leads += row.visit_done_leads;
        total.negotiation_leads += row.negotiation_leads;
      });
    }
    return total;
  }, [activeLeads]);

  const downloadCtx = useContext(DownloadContext);

  useEffect(() => {
    if (activeLeads !== undefined) {
      downloadCtx?.setActiveLeadRows(activeLeads);
    }
  }, [activeLeads, downloadCtx]);

  useEffect(() => {
    downloadCtx?.setTotalActiveLeadRow(totalRowByActiveLeads);
  }, [totalRowByActiveLeads, downloadCtx]);

  return (
    <>
      <RootContainer>
        <TableContainer>
          <Table
            columnGroups={[
              { span: 1, width: 200 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
            ]}
            columns={columns}
            rows={activeLeads || []}
            totalRow={totalRowByActiveLeads}
            renderTableRowCells={(row, index, isTotalRow) => [
              <AssignedCell
                name={row.employee.name}
                role={row.employee?.role}
                id={row.employee.id}
                disableDrillDown={
                  row.employee.isRoot || row.employee.role === "sales_associate"
                }
              />,
              <ActiveLeadTotalCell
                total={row.total_leads}
                employee={row.employee}
              />,
              <NewCell newNumber={row.new_leads} employee={row.employee} />,
              <ContactedCell
                contacted={row.contacted_leads}
                employee={row.employee}
              />,
              <InterestedCell
                interested={row.interested_leads}
                employee={row.employee}
              />,
              <VisitPlanedCell
                visitPlaned={row.visit_planned_leads}
                employee={row.employee}
              />,
              <VisitDoneCell
                visitDone={row.visit_done_leads}
                employee={row.employee}
              />,
              <NegotiationCell
                negotiation={row.negotiation_leads}
                employee={row.employee}
              />,
            ]}
          />
        </TableContainer>
      </RootContainer>
      <ProgressIndicator open={isLoading} />
    </>
  );
};

export default ActiveLeadsContent;
