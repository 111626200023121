import styled from "@emotion/styled";
import { FC } from "react";
import { Employee } from "../../..";
import { FilterUrlParams } from "../../../../../constants/filters";
import { useNavigateWithTeam } from "../../../../../hooks/useNavigationWithTeam";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../../../../hooks/useUrlQueryParam";
import {
  getActiveLeadStageQueryString,
  getLeadsFilterUrl,
} from "../../../../../utils/urlHelper";

interface ActiveLeadTotalCellProps {
  total: number;
  employee?: Employee | undefined;
  projectId?: string | undefined;
}
const RootContainer = styled.div(() => ({
  cursor: "pointer",
}));

const ActiveLeadTotalCell: FC<ActiveLeadTotalCellProps> = ({
  total,
  employee,
  projectId,
}) => {
  const navigate = useNavigateWithTeam();
  const [creationDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [leadSource] = useMultiSelectUrlQueryParam(FilterUrlParams.LEAD_SOURCE);

  const handleClick = () => {
    const queryString = getLeadsFilterUrl({
      creationDate,
      leadSource,
      projectId,
      employee
    });
    const activeStageQueryString = getActiveLeadStageQueryString();
    navigate(`/clients?${queryString}&${activeStageQueryString}`);
  };
  return <RootContainer onClick={handleClick}>{total}</RootContainer>;
};

export default ActiveLeadTotalCell;
