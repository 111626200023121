import { LeadStage } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../constants/filters";
import { Employee } from "../pages/AllLeads";

export function createRelativeUrlWithSearchParams(
  path: string,
  searchParams: Record<string, any>
) {
  const url = new URL(path, "http://salesleg.com");
  for (let paramName in searchParams) {
    if (Array.isArray(searchParams[paramName])) {
      for (let value of searchParams[paramName]) {
        url.searchParams.append(paramName, value);
      }
    } else if (typeof searchParams[paramName] === "string") {
      url.searchParams.append(paramName, searchParams[paramName]);
    }
  }

  return `${url.pathname}?${url.searchParams.toString()}`;
}

interface LeadFilter {
  creationDate?: string | undefined;
  leadSource?: string[] | undefined;
  projectId?: string | undefined;
  employee?: Employee | undefined;
  stage?: LeadStage | undefined;
  followUp?: string | undefined;
  isRoot?: boolean | undefined;
  prospectDate?: string | undefined;
  bookingDate?: string | undefined
};
export function getLeadsFilterUrl({creationDate, leadSource, projectId, employee, stage, followUp, isRoot, prospectDate, bookingDate}: LeadFilter) {
  const params = new URLSearchParams();
  if (creationDate) {
    params.append(FilterUrlParams.CREATION_DATE, creationDate);
  }

  if (leadSource && leadSource.length > 0) {
    leadSource.forEach((source) => {
      params.append(FilterUrlParams.LEAD_SOURCE, source);
    });
  }

  if (employee?.role === "sales_lead") {
    params.append(
      isRoot ? FilterUrlParams.ASSIGNEE : FilterUrlParams.MANAGER,
      employee.id
    );
  }

  if (employee?.role === "sales_associate" || employee?.role === "sales_head") {
    params.append(FilterUrlParams.ASSIGNEE, employee.id);
  }

  if (stage) {
    params.append(FilterUrlParams.STAGE, stage);
  }

  if (projectId) {
    params.append(FilterUrlParams.PROJECT, projectId);
  }

  if (followUp) {
    params.append(FilterUrlParams.FOLLOWUP, followUp);
  }

  if (prospectDate) {
    params.append(FilterUrlParams.PROSPECT_DATE, prospectDate)
  }

  if (bookingDate) {
    params.append(FilterUrlParams.PROSPECT_DATE, bookingDate)
  }

  return params.toString();
};

export const getActiveLeadStageQueryString = () => {
  const stages: LeadStage[] = [
    "new",
    "contacted",
    "interested",
    "visit_planned",
    "visit_done",
    "negotiation",
  ];

  const params = new URLSearchParams();

  stages.forEach((stage) => {
    params.append(FilterUrlParams.STAGE, stage);
  });

  return params.toString();
};
